<template>
  <section>
    <ModuleForm
      :module-name="$t('moduleForm.redirect')"
      :module="redirect"
      :show-header-info="disabled"
      :show-created-by="false"
      :show-modified-by="false"
    >
      <template #form-title>
        {{ $t(`route.${$route.name}`) }}
      </template>
      <template #header-buttons>
        <ModuleFormButton
          v-if="disabled && !isReadOnly"
          :required-permissions="requiredPermissions.editButton"
          icon="edit"
          @click="goToEdit"
        />
        <ModuleFormButton
          v-if="!isDisabled"
          :title="$t('buttons.save')"
          :required-permissions="requiredPermissions.saveButton"
          :disabled="loading"
          @click="save"
        />
        <ModuleFormDeleteButton
          v-if="redirect.id && !isReadOnly"
          :record-id="redirect.id"
          :required-permissions="requiredPermissions.deleteButton"
          :disabled="loading"
          store-name="redirectVlm"
          list-router-path="redirect"
        />
        <ModuleFormButton
          icon="close"
          @click="$router.push('/redirect')"
        />
      </template>
      <template #form-content>
        <Preloader v-if="loading" on-top />
        <div class="form-top-part">
          <div class="two-columns">
            <Input
              class="two-columns__column"
              :value="aliasType"
              id="redirect_aliasType"
              :label="$t('redirectVlm.aliasType')"
              :disabled="true"
            />

            <SiteSelect
              v-if="vlm"
              class="two-columns__column"
              v-model="redirect.siteId"
              @blur="$v.redirect.siteId.$touch()"
              :error="$v.redirect.siteId.$error"
              :options="sites"
              id="redirect_sites"
              :label="$t('redirectVlm.server')"
              :disabled="isDisabled"
              required
            />

            <Select
              v-else
              class="two-columns__column"
              v-model="redirect.siteId"
              @blur="$v.redirect.siteId.$touch()"
              :error="$v.redirect.siteId.$error"
              :options="sites"
              id="redirect_sites"
              :label="$t('redirectVlm.server')"
              :disabled="isDisabled"
              required
            />
          </div>
          <div class="three-columns">
            <Select
              class="three-columns__column"
              v-model="redirect.siteId"
              @blur="$v.redirect.siteId.$touch()"
              :error="$v.redirect.siteId.$error"
              :options="sites"
              option-title="url"
              id="redirect_sites"
              :label="$t('redirectVlm.server')"
              :disabled="true"
              required
            />
            <div class="three-columns__column input-validation">
              <!-- $v.redirect.alias.$reset(); is important here to mark 'alias' validation as not dirty and wait for async BE call -->
              <Input
                :value="redirect.alias"
                @input="value => { $v.redirect.alias.$reset(); redirect.alias = value; }"
                @blur="$v.redirect.alias.$touch()"
                :error="$v.redirect.alias.$error"
                id="redirect_alias"
                :label="$t('redirectVlm.alias')"
                :disabled="isDisabled"
                disable-form-group
                required
              />
              <div class="input-validation__info-text">
                {{ getFullAliasUrl() }}
              </div>
              <!-- This took hours of debugging. If you don't know how to fix this properly, DO NOT change the lines below! -->
              <div v-if="$v.redirect.alias.$dirty">
                <Preloader on-top v-if="$v.redirect.alias.$pending" />
                <div v-else-if="redirect.alias && redirect.siteId && redirect.validFrom" class="input-validation__icon">
                  <IconInvalid v-if="$v.redirect.alias.$error" class="input-validation__icon--failure" />
                  <IconValid v-else class="input-validation__icon--success" />
                </div>
              </div>
            </div>
            <Select
              class="three-columns__column"
              v-model="redirect.redirectType"
              @blur="$v.redirect.redirectType.$touch()"
              :error="$v.redirect.redirectType.$error"
              :options="redirectTypes"
              id="redirect_type"
              :label="$t('redirectVlm.redirectType')"
              :disabled="isDisabled"
              @change="redirectTypeChanged"
              no-empty-value
              required
            />
          </div>
          <div class="two-columns">
            <div class="two-columns__column input-validation">
              <!-- $v.redirect.originalUrl.$reset(); is important here to mark 'originalUrl' validation as not dirty and wait for async BE call -->
              <Input
                :value="redirect.originalUrl"
                @input="value => { $v.redirect.originalUrl.$reset(); redirect.originalUrl = value; }"
                @blur="$v.redirect.originalUrl.$touch()"
                :error="$v.redirect.originalUrl.$error"
                id="redirect_originalUrl"
                :label="$t('redirectVlm.originalUrl')"
                :disabled="isDisabled"
                disable-form-group
                required
              />
              <div v-if="$v.redirect.originalUrl.$error" class="input-validation__info-text error-message">
                {{ $t('redirectVlm.errors.nonExistingOriginalUrl') }}
              </div>
              <!-- This took hours of debugging. If you don't know how to fix this properly, DO NOT change the lines below! -->
              <div v-if="$v.redirect.originalUrl.$dirty">
                <Preloader on-top v-if="$v.redirect.originalUrl.$pending" />
                <div v-else-if="redirect.originalUrl" class="input-validation__icon">
                  <IconInvalid v-if="$v.redirect.originalUrl.$error" class="input-validation__icon--failure" />
                  <IconValid v-else class="input-validation__icon--success" />
                </div>
              </div>
            </div>
            <div class="redirect-status-label two-columns__column">
              <div>{{ $t('redirectVlm.status') }}</div>
              <RedirectStatusVlm :redirect="loadedRedirect" />
            </div>
          </div>

          <div class="two-columns">
            <Datepicker
              class="two-columns__column"
              v-model="redirect.validFrom"
              @blur="$v.redirect.validFrom.$touch()"
              :error="$v.redirect.validFrom.$error"
              :min-date="validityMinDate"
              id="redirect_validFrom"
              :label="$t('redirectVlm.validFrom') + ' *'"
              :placeholder="$t('redirectVlm.selectDate')"
              :show-set-now="!disabled"
              :disabled="isDisabled"
            />
            <Datepicker
              v-if="reservedUntilVisible"
              class="two-columns__column"
              v-model="redirect.reservedUntil"
              @blur="$v.redirect.reservedUntil.$touch()"
              :error="$v.redirect.reservedUntil.$error"
              :min-date="validityMinDate"
              id="redirect_reservedUntil"
              :label="$t('redirectVlm.reservedUntil') + ' *'"
              :placeholder="$t('redirectVlm.selectDate')"
              :disabled="isDisabled"
            />
          </div>
          <Input
            v-model="redirect.description"
            id="redirect_description"
            :label="$t('redirectVlm.description')"
            :disabled="isDisabled"
          />
        </div>
      </template>
    </ModuleForm>

    <AbstractDataTable
      v-if="redirect.id"
      :headers="tableHeaders"
      :items="redirect.audits"
      :total-count="redirect.audits.length"
      :show-actions="false"
      :current-page="1"
      :on-page-change="() => {}"
      store-name="redirectVlm"
      table-css-class="redirect-logs-table-vlm"
    >
      <template #tableBody="{ item }">
        <TitleColumn
          :title-header="tableHeaders[0].name"
          :title-value="item.modifiedAt | prettyDateTime"
          subtitle
        />
        <TitleColumn
          :title-header="tableHeaders[1].name"
          :title-value="getModifiedBy(item)"
        />
        <TitleColumn
          :title-header="tableHeaders[2].name"
          :title-value="getChangeLog(item)"
        />
      </template>
    </AbstractDataTable>
  </section>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { isURL } from '@/filters'
import CoreApi from '@/api/core'
import RedirectModel from '@/model/RedirectVlm'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import ModuleForm from '@/components/shared/ModuleForm.vue'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton.vue'
import Input from '@/components/form/inputs/Input.vue'
import ModuleFormButton from '@/components/shared/ModuleFormButton.vue'
import Preloader from '@/components/preloader/Preloader.vue'
import Select from '@/components/form/select/Select.vue'
import Datepicker from '@/components/form/Datepicker'
import TitleColumn from '@/components/table/columns/TitleColumn.vue'
import AbstractDataTable from '@/components/table/AbstractDataTable.vue'
import RedirectTypes, { REDIRECT_TYPE_PERMANENT, REDIRECT_TYPE_TEMPORARY } from '@/model/ValueObject/RedirectTypes'
import IconValid from '@/assets/img/svg/valid.svg?inline'
import IconInvalid from '@/assets/img/svg/invalid.svg?inline'
import RedirectStatusVlm from '@/components/redirect/vlm/RedirectStatusVlm'
import RedirectHelperServiceVlm from '@/services/redirect/RedirectHelperServiceVlm'
import { mapGetters } from 'vuex'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

const testAlias = alias => /^[a-zA-Z0-9\-/]+$/g.test(alias)
let currentAlias = null

const isAliasValid = async (value, redirect) => {
  const { siteId, alias, validFrom, reservedUntil } = redirect
  if (siteId && alias && validFrom && currentAlias !== redirect.alias) {
    if (!testAlias(alias)) {
      return false
    }
    try {
      const { isReserved } = (await CoreApi().post('/redirect/alias/validate', {
        alias,
        siteId,
        validFrom,
        reservedUntil: reservedUntil || null
      })).data
      return !isReserved
    } catch (error) {
      console.error(error)
      return false
    }
  }
  return true
}
const isOriginalUrlValid = async (value, redirect) => {
  const { originalUrl } = redirect
  if (originalUrl && isURL(originalUrl)) {
    try {
      const { isValid } = (await CoreApi().post('/redirect/route/validate', { originalUrl })).data
      return isValid
    } catch (error) {
      console.error(error)
      return false
    }
  }
  return false
}
export default {
  name: 'RedirectNewViewVlm',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SiteSelect,
    RedirectStatusVlm,
    AbstractDataTable,
    TitleColumn,
    Select,
    Preloader,
    ModuleFormButton,
    Input,
    ModuleFormDeleteButton,
    ModuleForm,
    Datepicker,
    IconValid,
    IconInvalid
  },
  data () {
    return {
      loading: false,
      redirect: this._.cloneDeep(RedirectModel),
      loadedRedirect: this._.cloneDeep(RedirectModel),
      redirectTypes: RedirectTypes,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_REDIRECT_PERMISSIONS
    }
  },
  validations: {
    redirect: {
      siteId: {
        required
      },
      originalUrl: {
        required,
        isOriginalUrlValid
      },
      alias: {
        required,
        isAliasValid
      },
      redirectType: {
        required
      },
      validFrom: {
        required
      },
      reservedUntil: {
        required: requiredIf(redirect => redirect.redirectType === REDIRECT_TYPE_TEMPORARY)
      }
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    isReadOnly () {
      return RedirectHelperServiceVlm.isRedirectReadOnly(this.loadedRedirect)
    },
    isDisabled () {
      return this.disabled || this.isReadOnly
    },
    reservedUntilVisible () {
      return this.redirect.redirectType !== REDIRECT_TYPE_PERMANENT
    },
    validityMinDate () {
      if (this.isDisabled) {
        return null
      }
      const minDate = new Date()
      minDate.setSeconds(minDate.getSeconds() - 1) // this is for 'setDateToNow()' in Datepicker.vue to work properly
      return minDate
    },
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    aliasType () {
      return RedirectHelperServiceVlm.getAliasType(this.redirect)
    },
    tableHeaders () {
      return [
        { name: this.$t('redirectVlm.audits.date') },
        { name: this.$t('redirectVlm.audits.user') },
        { name: this.$t('redirectVlm.audits.description') }
      ]
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async getRedirect () {
      await this.$store.dispatch('redirectVlm/fetchOne', this.$route.params.id)
        .then(() => {
          this.redirect = this.$store.getters['redirectVlm/detail']
          this.loadedRedirect = this._.cloneDeep(this.redirect)
          currentAlias = this.redirect.alias
        })
    },
    redirectTypeChanged (redirectType) {
      if (redirectType === REDIRECT_TYPE_PERMANENT) {
        this.redirect.reservedUntil = null
      }
    },
    getFullAliasUrl () {
      const { siteId, alias } = this.redirect
      if (siteId && alias) {
        const site = this.$store.getters['site/siteById'](siteId)
        return `${site.url}/${alias}`
      }
      return ''
    },
    getModifiedBy (audit) {
      const { userName, firstName, lastName } = audit.modifiedBy
      return `${firstName} ${lastName} (${userName})`
    },
    getChangeLog (audit) {
      const { prettyDate } = this.$options.filters
      const getRedirectTypeTitle = redirectTypeId => this.redirectTypes.find(t => t.id === redirectTypeId).title
      const getInfo = ({ propName, filter }) => {
        if (audit[propName]) {
          const label = this.$t(`redirectVlm.${propName}`)
          const value = filter ? filter(audit[propName]) : audit[propName]
          return `${label}: ${value}`
        }
        return ''
      }

      const auditInfo = [{ propName: 'alias' }, { propName: 'redirectType', filter: getRedirectTypeTitle }, { propName: 'validFrom', filter: prettyDate }, { propName: 'reservedUntil', filter: prettyDate }]
        .map(({ propName, filter }) => getInfo({ propName, filter }))
        .filter(info => Boolean(info))
        .join(', ')
      const label = this.$t(`redirectVlm.audits.${audit.action.toLowerCase()}`)
      return auditInfo ? `${label}, ${auditInfo}` : label
    },
    validateAsync () {
      return new Promise((resolve) => {
        this.$v.$touch()
        const interval = setInterval(() => {
          if (!this.$v.$pending) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    },
    async save () {
      await this.validateAsync()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.loading = true
        const action = this.redirect.id ? 'update' : 'create'
        const successNotify = this.redirect.id ? 'record_was_updated' : 'record_was_created'
        await this.$store.dispatch(`redirectVlm/${action}`, this.redirect)
          .then((response) => {
            const error = this.$store.getters['redirectVlm/error']
            if (!error) {
              NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
              if (action === 'create') {
                this.redirect.id = response.id
              } else if (response && typeof response === 'object') {
                this.redirect = response
                this.loadedRedirect = this._.cloneDeep(this.redirect)
                currentAlias = this.redirect.alias
              }
              this.goToEdit()
            } else {
              console.error(error)
              NotifyService.setErrorMessage(error)
            }
          })
          .catch(error => console.error(error))
          .finally(() => {
            this.loading = false
          })
      }
    },
    goToEdit () {
      this.$router.push(`/redirect/${this.redirect.id}/edit`)
    }
  },
  created () {
    currentAlias = null
  }
}
</script>

<style lang="scss">
.redirect-logs-table-vlm {
  margin-top: 2rem;
  &__thead,
  &__tr {
    grid-template-columns: rem(120px) repeat(auto-fit, minmax(100px, 1fr));
  }
}
</style>
<style lang="scss" scoped>
.form-top-part {
  gap: 2%;
  &__left {
    flex: 0 0 65%;
  }
  &__right {
    flex: 0 0 32%;
  }
}
.two-columns {
  display: flex;
  gap: 2%;
  &__column {
    flex: 0 0 49%;
  }
}
.three-columns {
  display: flex;
  gap: 2%;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__column {
    @include bp(0 7) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 32%;
    }
  }
}

.input-validation {
  position: relative;
  &__info-text {
    margin-top: rem(2px);
    margin-bottom: rem(10px);
  }
  &__icon {
    position: absolute;
    top: 2.1rem;
    right: 0.5rem;
    background: #fff;
    padding-left: rem(8px);

    &--success {
      fill: #51bb4e;
    }

    &--failure {
      fill: #df546e;
    }
  }
}
.redirect-status-label {
  padding-top: rem(5px);
  margin-bottom: rem(20px);
}
.error-message {
  @include font(400 13px "Roboto");
  padding-top: 0.2rem;
  color: #FF3455;
  text-align: right;
}
</style>
